// This file is used to create an axios instance that can be used to make API calls to the server.
import axios from 'axios';

let instance;

const apiClient = () => {
  // If the instance has already been instantiated then return it. Otherwise create a new instance.
  if (instance !== undefined)
    return instance;

    instance = axios.create({
        baseURL: process.env.REACT_APP_API_HOST
  });

  return instance;
}

 export default apiClient;
