import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadSpinner from "../Shared/Common-UI/LoadSpinner";
import Paging from "../Shared/Common-UI/Pagination";
import csvDownload from 'json-to-csv-export'
import apiClient from "../../api";

function FormularyUploadResult(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [validationResult,setValidationResult] = useState([]);
  const [validationFilteredResult, setValidationFilteredResult] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: 50,
    pageNumber: 1,
    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    async function fetchData(){
    if (location?.state?.file) {
      setLoading(true);
      const config = { headers: { "content-type": "multipart/form-data" } };
      var formData = new FormData();    
      formData.append('file', location?.state?.file);
      await apiClient()({ method: 'post', url: `/api/formularies/validate`, data: formData,config:config}).then(     
        (response) => {        
          setValidationResult(response?.data); 
          setValidationFilteredResult(response?.data);
          if(response?.data.length >50){
            setValidationFilteredResult(response?.data.slice(0, 50));
            pagination.totalCount = response?.data.length;
            pagination.totalPages = (Math.ceil(response?.data.length / 50))
            setPagination(pagination);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  }
  fetchData();
  }, [location?.state?.file,pagination]);

  const handlePageClick = (page) => {  
    var startIndex = (page - 1) * 50;
    var endIndex = Math.min(startIndex + (50 - 1), validationResult.length);
    setValidationFilteredResult(validationResult.slice(startIndex, endIndex));
    pagination.pageNumber = page;
    setPagination(pagination);
  };

  const exportCSVData = ()=>{
    const csvData = {
      data: validationResult,
      filename: 'formulary_upload_result',
      delimiter: ',',
      headers: ['lineNumber', "ndc", "altNdc","error"]
    } 
    csvDownload(csvData);
  }
 

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 py-3">
            <h1>Formulary Upload Result</h1>
            {loading ? <LoadSpinner/>:''}   
            <div className="row col-sm-4">
              <div className="p-2 text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-testid="exportBtn"
                  onClick={() => exportCSVData()}
                >
                  Export
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-testid="backButton"
                  onClick={() => navigate(`/formularies/`+ (location?.state?.id ? `edit/${location?.state?.id}`:'add'))}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="row col-sm-12">
              <label className="error p-2">
                Validation Mode: This In-House Formulary load produced this set
                of validation errors.
              </label>
            </div>
            <div className="p-2">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Line #</th>
                      <th>NDC</th>
                      <th>Alt NDC</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validationFilteredResult.length === 0 ? (
                      <tr>
                        <td className="no-data" colSpan="4">
                          <aside>No Error Found</aside>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {validationFilteredResult?.map((item, index) => {
                          return (
                            <tr key={`upload-error-key-${index}`}>
                              <td>{item.lineNumber}</td>
                              <td>{item.ndc}</td>
                              <td>{item.altNdc}</td>
                              <td>{item.error}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
                {pagination && pagination.totalCount > 50 && (
                  <Paging
                    pagination={pagination}
                    onPageChange={handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormularyUploadResult;
